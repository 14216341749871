<template>

  <div>

    <div class="d-flex justify-content-between navbar-dark w-100" style="padding-top: 88px;background-color: var(--primary-dark);overflow-y: scroll;scrollbar-width: none;-ms-overflow-style: none;">

      <router-link to="/" class="nav-item">
        <div class="nav-icon">
          <img src="/assets/images/general.png" :height="mainMenuIconSize">
          <div class="nav-text white-txt bold" style="text-align: center">Sports Book</div>
        </div>
      </router-link>

      <router-link to="/freebet" class="nav-item">
        <div class="nav-icon">
          <img src="/assets/images/freebet.svg" :height="mainMenuIconSize">
          <div class="nav-text white-txt bold" style="text-align: center">Freebet</div>
        </div>
      </router-link>

      <router-link to="/jackpot-games" class="nav-item">
        <div class="nav-icon">
          <img src="/assets/images/jackpot.png" :height="mainMenuIconSize">
          <div class="nav-text white-txt bold" style="text-align: center">Jackpot</div>
        </div>
      </router-link>

      <router-link to="/virtuals" class="nav-item">
        <div class="nav-icon">
          <img src="/assets/images/virtuals.jpeg" :height="mainMenuIconSize">
          <div class="nav-text white-txt bold" style="text-align: center">Virtuals</div>
        </div>
      </router-link>

      <router-link to="/casino" class="nav-item">
        <div class="nav-icon">
          <img src="/assets/images/casino.png" :height="mainMenuIconSize">
          <div class="nav-text white-txt bold" style="text-align: center">Casino</div>
        </div>
      </router-link>


      <router-link class="nav-item" v-for="(g,index) in games"  v-bind:key="getKey(index)" :to="{name: 'casinogames',params: {gameName: g.game_name,gameID: g.game_id,providerID: g.provider_id}}">
        <div class="nav-icon">
          <img v-bind:src="g.image" :height="mainMenuIconSize">
          <div class="nav-text white-txt bold" style="text-align: center">{{ shortenName(g.game_name,6) }}</div>
        </div>
      </router-link>

    </div>

    <!--  max-width: 768px;-->
    <div v-show="current_page !== 'casinogames' && current_page !== 'match'" class="d-flex justify-content-between main-menu w-100" style="overflow-y: scroll;scrollbar-width: none;-ms-overflow-style: none;">

      <!-- Soccer -->
      <a class="nav-item" @click="setSport(1)" v-bind:class="getMenuTextClass(1)" style="min-width: auto;margin: 0;padding-left: 0;padding-right: 0;">
        <div class="">
          <img alt="soccer" v-bind:src="getSportIcon(1)" v-bind:height="iconSize" onerror="this.src='/assets/sport-icons/1.png'"  style="margin: 2px;border-radius: 6px;"/>
          <div class="nav-text white-txt bold" style="font-size: 0.6875em !important;text-align: center">Soccer</div>
        </div>
      </a>

      <a v-for="(c,index) in sports" v-bind:key="getKey(index)" class="nav-item" @click="setSport(c.sport_id)" v-bind:class="getMenuTextClass(c.sport_id)" style="min-width: auto;margin: 0;padding-left: 0;padding-right: 0;">
        <div class="nav-icon">
          <img v-bind:alt="c.sport_name" v-bind:src="getSportIcon(c.sport_id)" v-bind:height="iconSize" onerror="this.src='/assets/sport-icons/1.png'" style="margin: 2px;border-radius: 6px;" />
          <div class="nav-text white-txt bold" style="font-size: 0.6875em !important;;text-align: center">{{ shortenName(c.sport_name,6) }}</div>
        </div>
      </a>

    </div>

  </div>

</template>

<style scoped>
  .icon-new.red {
    color: #fff;
    background-color: red;
  }
  .icon-new {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    font-size: 8px;
    background: #fff30a;
    background-color: rgb(255, 243, 10);
    padding: 2px 4px;
    color: red;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    font-weight: 700;
    z-index: 702;
  }
</style>
<script>

export default {
  name: "Menu",
  methods: {

    getKey: function(index){

      return Math.random().toString(11).replace('0.', 'sport-'+index + '-');

    },
    setSport: function (sportID) {

        this.$store.dispatch("setSportID",sportID);

        if(this.$store.state.current_page === 'home')
          return

        this.$router.push({name: 'home'});
    },
    getMenuTextClass: function (sportID){

      return this.current_page === "esport" || this.current_page === "freebet" ? "" :  parseInt(this.$store.state.sport_id) === parseInt(sportID) ? "menu-active" : "";

    },
    isActiveMenu: function (name){

      return this.current_page === name ? "menu-active" : "";

    },
    resetGames: function(){

      this.$store.dispatch("resetAllGames");

      //if(this.current_page !== "home") {

        this.goHome();

      //}

    }
  },
  computed: {
    iconSize: function (){

      return 40;

    },
    mainMenuIconSize: function (){

      return 55;

    },
    current_page: function () {

      return this.$store.state.current_page
    },
    show_freebet: function (){

      var p = this.getProfile();
      if(!p) {

        return true;

      }

      return parseInt(p.b4) > 0;

    },
    sports: function() {

      var s = this.$store.state.sports;

      var t = [];

      this.jQuery.each(s,function(k,v){

        if(v.sport_id > 1 ) {

          t.push(v);
        }

      })

      return t;
    },
  },
  data: function (){
    return {

      games: [
        {
          "category": "Popular",
          "description": "",
          "game_id": "JetX",
          "game_name": "JetX",
          "id": 27,
          "image": "https://storage.googleapis.com/maybet/smartsoft/JetX.png",
          "priority": 98,
          "provider_id": 5,
          "provider_name": "Smartsoft Gaming",
          "status": 1
        },
        {
          "category": "Top Picks",
          "description": "",
          "game_id": "1",
          "game_name": "Comet",
          "id": 1,
          "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_comet_crash_x3_3fac109cdf.png",
          "priority": 95,
          "provider_id": 3,
          "provider_name": "STP",
          "status": 1
        },
        {
          "category": "Top Picks",
          "description": "",
          "game_id": "vs9aztecgemsdx",
          "game_name": "Aztec Gems Deluxe",
          "id": 337,
          "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs9aztecgemsdx.png",
          "priority": 90,
          "provider_id": 1,
          "provider_name": "Pragmatic Play",
          "status": 1
        },
        {
          "category": "Virtual",
          "description": "",
          "game_id": "10159",
          "game_name": "Spin2Win",
          "id": 867,
          "image": "https://cdn.xpressgaming.net/images/47_9868579.png",
          "priority": 1,
          "provider_id": 9,
          "provider_name": "Golden races",
          "status": 1
        },
        {
          "category": "Top Picks",
          "description": "",
          "game_id": "rocketman",
          "game_name": "Rocket Man",
          "id": 667,
          "image": "https://storage.googleapis.com/maybet/elbet/rocketman.png",
          "priority": 99,
          "provider_id": 4,
          "provider_name": "Elbet",
          "status": 1
        },
      ]
    }
  }
}
</script>