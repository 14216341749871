<template>
  <span>

    <Menu></Menu>

    <Slider></Slider>

    <div class="body">

      <main role="main" class="container">

        <section>

          <div style="padding: 0.8125em 1.25em 0px;margin-top: 0px;border-width: 0.2em;border-style: solid;border-color: rgb(238, 238, 238);">
            <div class="d-flex flex-column mb-2">

              <div class="d-flex flex-column bold margin-top-sm">
                  <span style="font-size: var(--font-largest);">{{  profile.f1 }} {{  profile.f2 }} {{  profile.f3 }}</span>
              </div>

              <div class="d-flex flex-column bold">
                  <span style="font-size: var(--font-medium);">{{ profile.email }}</span>
              </div>

              <div class="font-header">Account Statement</div>

              <div class="row d-flex">
                  <div class="col d-flex flex-column" style="padding: .65em;">Balance: <span class="padding-account" style="font-size: var(--font-medium);">{{  profile.b1 | currency }}</span></div>
                  <div class="col d-flex flex-column" style="padding: .65em;">Bonus: <span class="padding-account" style="font-size: var(--font-medium);">{{  profile.b2 | currency }}</span></div>
              </div>

            </div>
          </div>

          <div style="padding: 0.8125em 1.25em 0px;margin-top: 0px;border-width: 0.2em;border-style: solid;border-color: rgb(238, 238, 238);">
            <div class="col-12 text-center">
                <h4 style="color: var(--primary)">Deposit</h4>
                <div>Please enter amount you want to deposit</div>
              </div>
            <div class="form">

                <div class="form-group" style="margin-bottom: -1em;">

                    <div style="display: flex;justify-content: space-around;">

                      <div class="btn btn-primary btn-sm depo-btn btn-round pr-3 pl-3 white-txt" @click="setAmount(33)">
                        <span class="bold">+</span> 33
                      </div>

                      <div class="btn btn-primary btn-sm depo-btn btn-round pr-3 pl-3 white-txt" @click="setAmount(99)">
                          <span class="bold">+</span> 99
                      </div>

                      <div class="btn btn-primary btn-sm depo-btn btn-round pr-3 pl-3 white-txt" @click="setAmount(298)">
                          <span class="bold">+</span> 298
                      </div>

                      <div class="btn btn-primary btn-sm depo-btn btn-round pr-3 pl-3 white-txt" @click="setAmount(499)">
                          <span class="bold">+</span> 499
                      </div>

                      <div class="btn btn-primary btn-sm depo-btn btn-round pr-3 pl-3 white-txt" @click="setAmount(1999)">
                          <span class="bold">+</span> 1999
                      </div>

                    </div>

                    <input type="number" v-model="amount" class="form-control small-txt" id="amount" aria-describedby="amountHelp" placeholder="KES 100">

                </div>

                <div  style="font-size: var(--font-small); text-align: left;">Minimum Kes 5</div>

                <div class="copy-betslip-btn" @click="deposit" v-bind:class="loading" style="margin-bottom: 1em;">
                    Deposit
                </div>

            </div>
          </div>

          <div style="padding: 0.8125em 1.25em 0px;margin-top: 0px;border-width: 0.2em;border-style: solid;border-color: rgb(238, 238, 238);">
            <div class="col-12 text-center">
                <h4 style="color: var(--primary)">Withdraw</h4>
                <div>Please enter amount you want to withdraw</div>
              </div>
            <div class="form">

                <div class="form-group" style="margin-bottom: -1em;">
                    <input type="number" v-model="withdraw_amount" class="form-control small-txt" aria-describedby="amountHelp" placeholder="KES 100">
                    <input type="hidden" name="utm_source" value="">
                    <input type="hidden" name="utm_medium" value="">
                    <input type="hidden" name="utm_campaign" value="">
                    <input type="hidden" name="referrer" value="">
                </div>

                <div  style="font-size: var(--font-small); text-align: left;">Minimum Kes 100</div>

                <div class="copy-betslip-btn" @click="withdraw" v-bind:class="loading"  style="margin-bottom: 1em;">
                    Withdraw
                </div>

            </div>
          </div>

          <div style="padding: 0.8125em 1.25em 0px;margin-top: 0px;border-width: 0.2em;border-style: solid;border-color: rgb(238, 238, 238);">

            <div class="row betslip" style="margin-bottom: 1em">

              <div class="col-12 text-center">
                <h4 style="color: var(--primary)">Refer a Friend</h4>
                <div  v-show="!has_referral_code">Refer a friend and get a bonus when they join</div>
                <div  v-show="has_referral_code">Refer a friend with your code <span style="font-weight: bold" v-text="code"></span> and get a bonus when they join</div>
              </div>

              <div class="col-12" v-show="!has_referral_code">

                <div class="form-group" style="margin-bottom: -1em;">
                    <input
                        v-model="new_referral_code"
                        type="text" maxlength="20"
                        class="form-control small-txt"
                        placeholder="Create a unique referral code" />
                </div>

                <ol style="margin-top: 1em" v-show="new_referral_code.length === 0 ">
                    <li>Suggest your unique referal code</li>
                    <li>Must be less than 20 characters</li>
                    <li>Should not contain any special characters</li>
                    <li>Can contains letters A-Z and numbers 0 - 9 combined</li>
                    <li>It should be one word</li>
                </ol>

                <ol style="margin-top: 1em" v-show="errors.length > 0 ">
                    <li class="error" v-for="(e,i) in errors" v-text="e" v-bind:key="i"></li>
                </ol>

              </div>

              <div style="margin-top: 1em" class="col-12 text-center" v-show="!has_referral_code">
                <div class="copy-betslip-btn" @click="createReferralLink">
                  Generate Invite Link
                </div>
              </div>

              <div style="margin-top: 1em" class="col-12 text-center" v-show="has_referral_code">
                <div class="copy-betslip-btn" @click="shareInvite">
                  Share Invite Link
                </div>
              </div>

            </div>

          </div>

        </section>
    </main>
      <!-- /.container -->

      <section class="pb-1 pt-1 jumbotron jumbotron-fluid">
          <div class="container">
              <hr class="my-1">
              <div class="container">
                  <div class="row">
                      <div class="col-auto">
                          <span class="black"><LogoutIcon></LogoutIcon></span>
                      </div>
                      <div class="col-auto">
                          <span><a class="black-txt" style="font-size: 1em !important;" @click="logout">Logout</a></span>
                      </div>
                  </div>
              </div>
              <hr class="my-1">
          </div>
      </section>

      <BottomFooter></BottomFooter>
      <bottom-navigation active-bets="0" page="setting"></bottom-navigation>

    </div>

    <span id="open-shareinvite" class="hidden"></span>

    <!-- The Modal -->
    <div class="sharebet-modal" id="shareinvite-modal">

      <div class="sharebet-modal-content">

        <div class="row d-flex">

          <div class="col-12 share-bet-title text-center justify-content-center">
            <strong>Share Your Invite Code</strong>
          </div>

          <div class="col-12 share-bet-desc text-center" @click="copyCode">
            <div class="booking-code">
              <strong>{{ code }}</strong>
            </div>
            <span style="font-size: 0.7em;">Ask your friend to send</span><br /> <strong>accept#{{ code }} to 40600</strong>
            <div style="font-size: 0.5em;">{{ copyBookingCode }}</div>
          </div>

        </div>

        <div class="row d-flex">

          <div class="col-12 btn-group-sm w-100">

            <a
                data-action="share/whatsapp/share" target="_blank"
                onclick="window.open('whatsapp://send?text=' + encodeURIComponent(document.getElementById('share-invite-msg').textContent)); return false;"
                class="btn share-bet-social whatsapp" style="font-size: 0.6em;">
              WhatsApp
            </a>

            <a
                href="https://www.facebook.com/sharer/sharer.php?u=&t="
                title="Share on Facebook"
                onclick="window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(document.getElementById('share-bet-link').textContent) + '&t=' + encodeURIComponent(document.getElementById('share-invite-msg').textContent)); return false;"
                target="_blank"
                class="btn share-bet-social facebbok" style="font-size: 0.6em;">
              Facebook
            </a>

            <a
                href="https://twitter.com/intent/tweet?"
                target="_blank" title="Tweet"
                onclick="window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(document.getElementById('share-invite-msg').textContent)); return false;"
                class="btn share-bet-social twitter" style="font-size: 0.6em;">
              Twitter
            </a>

          </div>

          <div class="col-12">
            <div class="share-bet-link" id="share-bet-link">{{ link }}</div>
            <div class="hidden" id="share-bet-code">{{ code }}</div>
            <div class="hidden" id="share-invite-msg">{{ msg }}</div>
            <div class="share-bet-copy" @click="copyShareBetLink">{{ copyText }}</div>
          </div>

        </div>

      </div>
    </div>

  </span>

</template>

<script>

import BottomFooter from './BottomFooter'
import Menu from './Menu'
import Slider from './Slider'
import BottomNavigation from './BottomNavigation'
import axios from "@/services/api";
const LogoutIcon= () => import(/* webpackChunkName: "material-icons" */  'vue-material-design-icons/Logout.vue');

export default {

  name: 'Setting',
  components: {
    Menu,
    BottomFooter,
    BottomNavigation,
    Slider,
    LogoutIcon
  },
  data: function () {
    return {
      amount: 0,
      loading: '',
      withdraw_amount: 0,
      new_referral_code: '',
      errors: [],
      code: '',
      link:'',
      copyText: 'Copy',
      copyBookingCode: 'Click to copy',
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        // react to route changes...
        document.title = "My tapabet Account";
        document.description = "Manage tapabet account";
      }
    },
    new_referral_code: function(n){

      console.log('got new value here '+n);

      this.validateReferralLink();
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","setting");
    var p = this.getProfile();
    if(!p) {

      this.setError("Login","Please login to proceed");
      this.$router.push({ name: 'login', params: { } });
      return;

    }
    this.reloadProfile();
    this.code = p.referral_code;
    this.link = p.referral_link;
    this.initShareInviteModal();

  },
  methods: {
    setAmount: function (amount) {

      this.amount = amount
    },
    deposit: function () {

      this.reset();
      var p = this.getProfile();
      if(!p) {

        this.setError("Login","Please login to proceed");
        this.$router.push({ name: 'login', params: { } });
        return;

      }

      if(this.amount < 1 ) {

        this.setError("Invalid Amount","ENter amount atleast 1 KSH or above");
        return;

      }

      var vm = this;
      var path = process.env.VUE_APP_URL_DEPOSIT.replace("{profile_id}", p.d);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      var data = {
        amount: parseInt(this.amount),
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer,
      };

      vm.loading = 'loading';

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.message;
            vm.setSuccess("Deposit Initiated",msg);

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;
              } else {

                vm.setError("Failed", err.response.data.message)
                console.log(JSON.stringify(err.response.data.message));

              }

            } else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            } else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })
    },
    createReferralLink: function () {

      this.reset();
      this.errors = [];

      var p = this.getProfile();
      if(!p) {

        this.setError("Login","Please login to proceed");
        this.$router.push({ name: 'login', params: { } });
        return;

      }

      if(this.new_referral_code.length < 4 ) {

        this.errors.push('Referral code should be more than 3 characters');

      }

      if(this.new_referral_code.length > 20 ) {

        this.errors.push('Referral code should be less than 21 characters');

      }

      if (!/^[0-9a-zA-Z]+$/.test(this.new_referral_code)) {

        this.errors.push('Referral code can only contain letters A-Z and numbers 0 - 9');

      }

      if(this.errors.length > 0 ) {

        return;
      }

      var vm = this;
      var path = process.env.VUE_APP_URL_GENERATE_REFERRAL_LINK.replace("{profile_id}", p.d);

      var data = {

        code: this.new_referral_code
      };

      vm.loading = 'loading';

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            vm.link = res.data.message.profile.referral_link
            vm.code = res.data.message.profile.referral_code
            var prof = res.data.message.profile
            var msg = res.data.message.description

            if(prof) {

              vm.setProfile(prof);
            }

            vm.setSuccess("Link Generated",msg);

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                vm.setError("Failed", err.response.data.message)
                vm.errors.push(err.response.data.message);

              }

            } else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            } else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })
    },
    validateReferralLink: function () {

      this.errors = [];

      if(this.new_referral_code.length < 4 ) {
        this.errors.push('Referral code should be more than 3 characters');
      }

      if(this.new_referral_code.length > 20 ) {
        this.errors.push('Referral code should be less than 21 characters');
      }

      if (!/^[0-9a-zA-Z]+$/.test(this.new_referral_code)) {
        this.errors.push('Referral code can only contain letters A-Z and numbers 0 - 9');
      }

    },
    withdraw: function () {

      this.reset();
      var p = this.getProfile();
      if(!p) {

        this.setError("Login","Please login to proceed");
        this.$router.push({ name: 'login', params: { } });
        return;

      }

      if(this.withdraw_amount < 100 ) {

        this.setError("Invalid Amount","Enter amount atleast 100 KSH or above");
        return;
      }

      var vm = this;
      var path = process.env.VUE_APP_URL_WITHDRAW.replace("{profile_id}", p.d);

      var data = {
        amount: parseInt(this.withdraw_amount)
      };

      vm.loading = 'loading';

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var msg = res.data.message;
            vm.setSuccess("Withdrawal Initiated",msg);

          })
          .catch(err => {

            vm.loading = '';

            if (err.response) {

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {

                vm.setError("Failed", err.response.data.message)
                console.log(JSON.stringify(err.response.data.message));
              }

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })
    },
    shareInvite: function(){

      document.getElementById("open-shareinvite").click();

    },
    copyShareBetLink: function () {

      var link = this.link;
      this.copyToClipboard(link);
      this.copyText = 'Copied';

    },
    copyCode: function () {

      var link = "accept#"+this.code;
      this.copyToClipboard(link);
      this.copyBookingCode = 'Invite Code Copied';

    },
    initShareInviteModal: function(){

      var modal = document.getElementById("shareinvite-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("open-shareinvite");

      // Get the <span> element that closes the modal
      //var span = document.getElementsByClassName("sharebet-close")[0];

      // When the user clicks on the button, open the modal
      btn.onclick = function() {

        modal.style.display = "block";
      }

      // When the user clicks on <span> (x), close the modal
      /*
      span.onclick = function() {
        modal.style.display = "none";
      }
      */

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function(event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      }

      document.addEventListener("click", e => {

        if (e.target == document.querySelector("#shareinvite-modal")) {

          modal.style.display = "none";
        }

      });

    },

  },
  computed: {

    msg: function(){

      return "Karibu tapabet, Come let's win BIG together. Join now and get a FREEBET. Send ACCEPT#"+this.code+" to 40600 or click on this link. \n"+this.link;

    },
    profile: function() {
      return this.getProfile();
    },
    has_referral_code: function (){

      return this.code !== undefined && this.code.length > 3;

    },

  },
  filters: {
    currency: function (amount) {

      if (amount == null) {

        amount = 0;
      }

      return (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'KES',
      });

    },
    formatOdds: function (x) {

      if (x === undefined) {

        return 1;

      }

      return parseFloat(x).toFixed(2);
    }
  },
}
</script>