<template>
  <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">

      <div class="carousel-item active">
        <lazy-image
            id="carousel_2"
            class="img-fluid img-container lozad"
            src="/assets/images/carousel_2.jpg"
        />
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: "Slider",
  methods:{
    isActiveMenu: function (name){

      return this.current_page === name ? "menu-active" : "";

    },
  },
  computed: {
    current_page: function () {

      return this.$store.state.current_page
    },
  }
}
</script>